<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>{{ inboxType }}</h1>
    </div>
    <div class="vap-page-content">
      <div v-if="apiToken">
        <div v-if="tableData.Rows">
          <div v-if="tableData.Rows.Values && tableData.Rows.Values.length > 0" class="main-table-container vasion-html-table-default">
            <VasionTable
              id="inbox-grid"
              :headerColumns="tableData.Columns.Values"
              :tableRows="tableData.Rows.Values"
              :hideColumns="hideColumns"
              :supportSorting="true"
              @newList="setCheckedDocuments"
              @vasion-column-sort="sortColumns"
              @vasion-selection="handleRowSelect"
            />
          </div>
          <div v-else>
            No content to display
          </div>
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="email-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import VasionSnackbar from '@/components/shared/VasionSnackbar.vue';
import { tableSorterWithTransform } from '@/store/helperModules/common.module'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'TheGeneralSignatureInbox',
  components: {
    Loading,
    VasionSnackbar,
  },
  data: function () {
    return {
      actionsConfig: {
        reassignDisable: true,
        rejectDisable: true,
      },
      checkAllSelected: false,
      checkedDocuments: [],
      favorites: ['', '', '', ''],
      hideColumns: ['DocumentID', 'SignatureDocumentID'],
      loaderBackgroundColor,
      loaderColor,
      localPageSizeOptions: [],
      modalConfig: {
        buttonGroup: 'end',
        confirmButtonText: 'Yes',
        message: '',
        modalId: '',
        modalKey: '',
        modalTitle: '',
        modalType: 'message',
        rejectButtonText: 'No',
      },
      multipleDocuments: false,
      recents: ['', '', '', ''],
      rejectableDocuments: [],
      reassignableDocuments: [],
      selectedPageSize: {
        name: '25',
        value: 25,
      },
      showActions: false,
      showApproveDialog: false,
      showDownloadModal: false,
      showEmailModal: false,
      showGeneralModal: false,
      showSnackbarBool: false,
      singleDocument: '',
      sortAscending: true,
      sortBy: 0,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      tableData: [],
      tempDocumentID: 0,
      tempWorkflowID: 0,
      today: new Date(),
      VasionUserGroupSelect: 'VasionUserGroupSelect',
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
    checkedDocumentsString() { return !this.checkedDocuments || this.checkedDocuments.length === 0 ? '' : this.checkedDocuments.join(',') },
    currentPageNumber() { return !this.$store.state.digitalSignature.currentPage ? 1 : this.$store.state.digitalSignature.currentPage },
    disableNextButtons() { return this.currentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.currentPageNumber <= 1 },
    isCompletedData() { return this.$store.state.mainViews.mainNav && this.$store.state.mainViews.mainNav.endsWith('-completed') },
    isLoading() { return this.$store.state.digitalSignature.isLoading },
    inboxType() { return this.$store.state.digitalSignature.inboxType },
    pageDisplay: {
      get: function () {
        return `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    pageSize: {
      get: function () {
        return this.$store.state.digitalSignature.signaturePageSize
      },
      set: function (newSize) {
        this.$store.dispatch('digitalSignature/setSignaturePageSize', newSize)
      },
    },
    pageSizeOptions() { return this.$store.state.common.pageSizeOptions },
    totalPages() { return !this.$store.state.digitalSignature.signatureTotalPages ? 1 : this.$store.state.digitalSignature.signatureTotalPages },
    userID() { return this.$store.state.common.userID },
  },
  watch: {
    checkedDocuments: function () {
      const notPermittedToReject = []
      const notPermittedToReassign = []
      Object.values(this.checkedDocuments).forEach((checkedDocumentId) => {
        if (!this.rejectableDocuments.includes(checkedDocumentId)) {
          notPermittedToReject.push(checkedDocumentId)
        }
      })
      Object.values(this.checkedDocuments).forEach((checkedDocumentId) => {
        if (!this.reassignableDocuments.includes(checkedDocumentId)) {
          notPermittedToReassign.push(checkedDocumentId)
          this.actionsConfig.reassignDisable = true
        }
      })

      if (notPermittedToReject.length > 0) {
        this.actionsConfig.rejectDisable = true
      } else if (notPermittedToReassign.length > 0) {
        this.actionsConfig.reassignDisable = true
      } else {
        this.actionsConfig.rejectDisable = false
        this.actionsConfig.reassignDisable = false
      }
    },
    inboxType: function () { this.refreshData() },
    modalConfig: function () { this.$forceUpdate() },
    pageSize: function () { this.goToFirstPage() },
    selectedPageSize: {
      handler: function () {
        if (this.selectedPageSize && this.selectedPageSize.name) {
          this.pageSize = this.selectedPageSize.value
        }
      },
      deep: true, // if .name changes on pages size, then we'll pick it up
    },
  },
  created() {
    this.localPageSizeOptions = this.pageSizeOptions.map((element) => {
      return {
        name: element.toString(),
        value: element,
      }
    })

    const localPageSize = this.pageSize && this.pageSize > 0 ? this.pageSize : 25

    this.selectedPageSize = this.localPageSizeOptions.find((option) => {
      return option.value === localPageSize
    })

    this.refreshData()
  },
  methods: {
    actionsClick() {
      if (this.checkedDocuments.length > 0) {
        this.showActions = !this.showActions
      }
    },
    async confirmButtonClick(modalPayload, modalCallback) { await modalCallback(modalPayload) },
    goToFirstPage() { this.goToPage(1) },
    goToLastPage() { this.goToPage(this.totalPages) },
    goToNextPage() { this.goToPage(this.currentPageNumber + 1) },
    async goToPage(pageNumber) {
      let localPageNumber = pageNumber

      if (!pageNumber || pageNumber <= 0) {
        localPageNumber = 1
      } else if (pageNumber > this.totalPages) {
        localPageNumber = this.totalPages
      }

      await this.$store.dispatch('workflow/setWorkSpaceCurrentPage', localPageNumber)
      this.refreshData()
    },
    goToPreviousPage() {
      this.goToPage(this.currentPageNumber - 1)
    },
    // eslint-disable-next-line
    async handleRowSelect(selectInfo) {
      if (selectInfo.Values && selectInfo.Values[1] && selectInfo.Values[1] !== '' && selectInfo.Values[1] !== '0') {
        const documentId = selectInfo.Values[0]
        const [, documentDetails] = await Promise.all([
          this.$store.dispatch('document/setBackRoute', `/digitalSignature/${this.inboxType}`),
          this.$store.dispatch('document/getDocumentDetails', { documentID: documentId }),
        ])
        const shouldOpenInDocumentView = documentDetails?.pendingSignatureDocumentZones?.length > 0
        const startingPageNumber = shouldOpenInDocumentView ? documentDetails.pendingSignatureDocumentZones[0].pageNumber : null
        if (shouldOpenInDocumentView) {
          this.$router.push({ name: 'Document', params: { documentID: documentId, startingPage: startingPageNumber } })
          } else {
            this.$router.push({ name: 'DocumentDataWithStartingPage', params: { documentID: documentId, startingPage: startingPageNumber } })
        }
      }
    },
    async refreshData() {
      let inboxDispatch = ''
      switch (this.inboxType) {
        case 'Inbox':
          inboxDispatch = 'getInboxSignatureDocuments'
          break
        case 'Sent':
          inboxDispatch = 'getSentSignatureDocuments'
          break
        default:
          inboxDispatch = 'getInboxSignatureDocuments'
      }
      this.$store.dispatch('digitalSignature/setIsLoading', true)
      this.$store.dispatch('common/getUsers')
      this.$store.dispatch('common/getGroups')
      await this.$store.dispatch(`digitalSignature/${inboxDispatch}`)
      this.refreshTableData()
      this.checkedDocuments = []
      this.$store.dispatch('digitalSignature/setIsLoading', false)
    },
    async refreshTableData(tableData) {
      if (tableData) {
        this.$store.commit('digitalSignature/setSignatureData', tableData)
      }

      this.tableData = this.$store.state.digitalSignature.signatureData
    },
    setCheckedDocuments(newList) {
      this.checkedDocuments = newList.map(item => {
        return item.Values[1]
      })
    },
    showSnackbar(value) {
      const [response] = value
      this.snackbarTitle = response ? 'Email successfully sent!' : 'Unable to send email';
      this.snackbarSubTitle = response ? 'Thank you for using Vasion\'s email service' : 'Please try again or contact your administrator';
      this.snackbarImage = response
      this.showSnackbarBool = true;
    },
    async sortColumns(options) {
      this.$store.dispatch('digitalSignature/setIsLoading', true)

      let columnLegend = {
        'Subject': 2,
        'Primary ID': 3,
        'Sent To': 4,
        'Status': 5,
        'Due Date': 6
      }

      let tableDataCopy = JSON.parse(JSON.stringify(this.tableData))
      tableDataCopy.Rows.Values.sort(tableSorterWithTransform(
        x => x.Values[columnLegend[options.columnName]],
        options.ascending
      ))

      this.refreshTableData(tableDataCopy)

      this.$store.dispatch('digitalSignature/setIsLoading', false)
    },
    toggleGeneralModal(modalConfig, functionName) {
      if (this.checkedDocuments.length > 0) {
        this.modalConfig = modalConfig
        this.modalConfig.confirmButtonCallback = functionName
        this.showActions = false
        this.showGeneralModal = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.inboxGridDiv {
  height: calc(100vh - 224px);
  overflow: auto;
}
</style>
